import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import { useCart } from "../../api/stateContext";

const HeadActions = () => {
  const { itemCount } = useCart(); // Access itemCount directly

  const [hideRoutes, setHideRoutes] = useState(false);

  useEffect(() => {
    let includeRoutes = ["/dashboard"];
    if (includeRoutes.includes(window.location.pathname)) {
      setHideRoutes(false);
    } else {
      setHideRoutes(true);
    }
  }, [window.location.pathname]);

  if (hideRoutes) {
    return null;
  } else {
    return (
      <div class="header-actions">
        <Link to="/order-progress" className="action-icon-wrapper clickable">
          <div className="badge">{itemCount}</div>
          <img className="action-icon" alt="Cart" src="frame-633116.svg" />
        </Link>
        <div
          onClick={() => {
            window.open("https://wa.me/+916381975763", "_blank");
          }}
          rel="noopener noreferrer"
          className="action-icon-wrapper clickable"
        >
          <img
            className="action-icon whatsapp"
            alt="WhatsApp"
            src="whatsapp.svg"
          />
          {/* <img
            className="action-icon"
            alt="WhatsApp"
            src="ph-whatsapp-logo-thin.svg"
          /> */}
        </div>

        <div
          onClick={() => {
            window.open("tel:+916381975763", "_blank");
          }}
          rel="noopener noreferrer"
          className="action-icon-wrapper clickable"
        >
          <img className="action-icon whatsapp" alt="WhatsApp" src="call.png" />
        </div>
      </div>
    );
  }
};

export default HeadActions;
