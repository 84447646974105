import React, { useState, useEffect } from "react";
import Swal from "sweetalert2";
import "./style.css";
import { getUser, getCategories, getDefaultAddress, getCartCount, getBanner } from "../../api/Api"; // Assuming this function exists
import Navigation from "./Navigation";
import { Link, useNavigate } from "react-router-dom";
import HeadActions from "../../components/HeadActions/HeadActions";
import { useCart } from "../../api/stateContext";
import { useSwipeable } from 'react-swipeable';

export const Dashboard = () => {
  const [slides, setSlides] = useState([
    {
      banner: "banner1.jpg",
      header: "Refill your Medicines on time",
      subHeader: "Our experts will contact you for your refills on medicines",
      image: "image-12.png",
    },
    {
      banner: "banner2.jpg",
      header: "Stay Healthy and Safe",
      subHeader: "We provide timely updates on your health needs",
      image: "image-12.png",
    },
    {
      header: "Medication Reminders",
      subHeader: "Never miss a dose with our automated reminders",
      image: "image-12.png",
    },
  ]);

  const [user, setUser] = useState({
    name: "",
    location: "",
  });

  const [videoSlides] = useState([
    {
      videourl: "https://www.youtube.com/embed/7e90gBu4pas",
    },
    {
      videourl: "https://www.youtube.com/embed/7e90gBu4pas",
    },
    {
      videourl: "https://www.youtube.com/embed/7e90gBu4pas",
    },
  ]);

  const [categories, setCategories] = useState([]); // State to manage categories
  const {dispatch} = useCart();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [transformX, setTransformX] = useState(0);

  const navigate = useNavigate();

  const handleDotClick = (index) => {
    let leftwidth = 0
    for(let i=0; i<index; i++){
      leftwidth = leftwidth + parseFloat(window.getComputedStyle(document.querySelector('.slider-' + i)).width.replace('px', ''));
    }
    const distance = -leftwidth;
    setTransformX(distance);
    setCurrentIndex(index);
  };

  const [currentVIndex, setCurrentVIndex] = useState(0);
  const [transformVX, setVTransformX] = useState(0);

  const handleDotVClick = (index) => {
    const distance = index * -220;
    setVTransformX(distance);
    setCurrentVIndex(index);
  };

  const handleVideoClick = (videoUrl) => {
    Swal.fire({
      html: `<iframe width="100%" height="315px" src="${videoUrl}?autoplay=1" frameborder="0"></iframe>`,
      width: "100%",
      padding: "0em",
      background: "#fff",
      showConfirmButton: false,
      confirmButtonColor: "#3085d6",

      onClose: () => {
        Swal.close();
      },
    });
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = getUser(); // Assuming getUser fetches user details from storage or an API

        console.log("User data", data);

        if (data.isLoggedIn) {
          const userData = { ...data };

          // If selectedAddress exists, fetch the default address
          if (userData.selectedAddress) {
            try {
              const response = await getDefaultAddress();

              if (response && response.default_address) {
                const { address1, state } = response.default_address;
                setUser({
                  name: userData.firstName || userData.name,
                  location: `${address1}, ${state}`, // Set location as address1 + state
                });
                return; // Exit early if the default address is found and set
              } else {
                setUser({
                  name: userData.firstName || userData.name,
                  location: "", // If default address is empty, set location to an empty string
                });
              }
            } catch (error) {
              console.error("Error fetching default address", error);
              setUser({
                name: userData.firstName || userData.name,
                location: "", // Handle error case by setting location to an empty string
              });
            }
          } else {
            setUser({
              name: userData.firstName || userData.name,
              location: userData.defaultAddress || "",
            });
          }
        }
      } catch (error) {
        console.error("Error fetching user data", error);
      }

      // Geolocation logic only runs if user.location is still empty
      if (!user.location) {
        // No user logged in, request location
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            async (position) => {
              const { latitude, longitude } = position.coords;
              // Reverse geocode to get the location name
              const locationResponse = await fetch(
                `https://geocode.maps.co/reverse?lat=${latitude}&lon=${longitude}&api_key=66d4782bb3d09960167594uts506c6d`
              );
              const locationData = await locationResponse.json();
              const city = locationData.address.city || "Unknown City";
              const area = locationData.address.road || "Unknown Area";
              setUser((prevState) => ({
                ...prevState,
                location: `${area}, ${city}`,
              }));
            },
            (error) => {
              console.error("Error obtaining location", error);
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
        }
      }
    };

    fetchUserData();
    
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      Swal.fire({
        title: "Loading...",
        text: "Fetching categories, please wait",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      try {
        const categoryData = await getCategories(); // Fetch categories from API
        setCategories(categoryData); // Update categories state
        const bannerData = await getBanner("home_banner");
        setSlides(bannerData);

        // setSlides(bannerData);
        Swal.close(); // Close the loader
      } catch (error) {
        console.error("Error fetching categories", error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to load categories!",
        });
      }
    };

    fetchCategories();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex = (currentIndex + 1) % slides.length;
      handleDotClick(nextIndex);

      const nextVIndex = (currentVIndex + 1) % videoSlides.length;
      handleDotVClick(nextVIndex);
    }, 5000);

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [currentIndex, slides.length, currentVIndex, videoSlides.length]);

 const handleCategories = (category) => {
  navigate("/searchbox", { state: { category: category } });
}
  useEffect(() => {
    const fetchCartCount = async () => {
      try {
        const userData = getUser();
        if (userData.isLoggedIn) {
          const cartCount = await getCartCount(userData.id); // Fetch cart count
          let cartItems = cartCount.cart_items.split(";");
          // remove empty strings
          cartItems = cartItems.filter((item) => item !== "");
          const cartItemCount = cartItems.length;
          dispatch({ type: 'UPDATE_COUNT', payload: cartItemCount });

        }
      } catch (error) {
        console.error("Failed to fetch cart count", error);
      }
    };

    fetchCartCount();
  }, []); // Add dependencies if needed


  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      const nextIndex = (currentIndex + 1) % slides.length;
      handleDotClick(nextIndex);
    },
    onSwipedRight: () => {
      const prevIndex = (currentIndex - 1 + slides.length) % slides.length;
      handleDotClick(prevIndex);
    },
  });

  const swipeVHandlers = useSwipeable({
    onSwipedLeft: () => {
      const nextVIndex = (currentVIndex + 1) % videoSlides.length;
      handleDotVClick(nextVIndex);
    },
    onSwipedRight: () => {
      const prevVIndex = (currentVIndex - 1 + videoSlides.length) % videoSlides.length;
      handleDotVClick(prevVIndex);
    },
  });


  return (
    <>
      <div className="dashboard">
        <div className="dashboard-container">
          <div className="dashboard-item">
            <div className="overlap">
              <div className="frame"></div>

              <HeadActions />

              <img
                className="finalmiglogo"
                alt="Finalmiglogo"
                src="migfulllogo.png"
              />
              <Link to="/savedaddress" className="rectangle-8 clickable" />
              <div className="frame-3 clickable">
                <img className="location" alt="Location" src="location.svg" />
                <div className="text-wrapper">{user.location}</div>
              </div>
              <Link to="/savedaddress" className="text-wrapper-2 clickable">
                Change
              </Link>
            </div>
          </div>
          <div className="dashboard-item">
            <p className="hey-skanda">
              <span className="span">
                {user.name !== "" ? "Hey " : "Welcome"}
              </span>
              <span className="text-wrapper-4">
                {user.name.substring(0, 12)}!
              </span>
            </p>
          </div>

          <div className="dashboard-item">
            <Link to="/searchbox" className="overlap-wrapper">
              <div className="overlap-4">
                <img className="search" alt="Search" src="search.svg" />
                <p className="p">What are you looking for..</p>
                <img
                  className="fluent-mic"
                  alt="Fluent mic"
                  src="fluent-mic-20-regular.svg"
                />
              </div>
            </Link>
          </div>

          <div className="dashboard-item">
            <div
              className="slider"
              style={{
                transform: `translateX(${transformX}px)`,
                transition: "transform 0.3s ease",
              }}
              {...swipeHandlers}
            >
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`overlap-group-wrapper slider-${index} ${
                    index === currentIndex ? "active" : ""
                  }`}
                >
                  {slide.banner ? (
                    <img
                      className="banner"
                      alt="Banner"
                      src={"https://medingen.s3.us-east-1.amazonaws.com/banner/" + slide.banner}
                      />
                  ) : (
                  <div className="overlap-5">
                    <img
                      className="image"
                      alt="Slide Image"
                      src={slide.image}
                    />
                    <div className="frame-10">
                      <p className="text-wrapper-8">{slide.header}</p>
                      <p className="text-wrapper-9">{slide.subHeader}</p>
                    </div>
                  </div>
                  )}
                </div>
              ))}
            </div>

            <div className="frame-11">
              {slides.map((_, index) => (
                <div
                  key={index}
                  className={`rectangle-${
                    index === currentIndex ? "23" : "24"
                  }`}
                  onClick={() => handleDotClick(index)}
                />
              ))}
            </div>
          </div>

          <div className="dashboard-item">
            <div className="group-wrapper">
              <div className="frame-wrapper">
                <div className="frame-6">
                  <div className="frame-7">
                    <div className="text-wrapper-5">
                      Order with Prescription
                    </div>
                    <p className="text-wrapper-6">
                      You can take a photo of your prescription or select from
                      the gallery
                    </p>
                  </div>
                  <Link to="/upload-prescription" className="frame-8">
                    <img className="vector" alt="Vector" src="vector.svg" />
                    <div className="text-wrapper-7">Upload Now</div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="dashboard-item">
            <div className="frame-12">
              <Link to={"/rewards"} className="frame-13">
                <div className="image-wrapper">
                  <img className="image-2" alt="Image" src="image-20.png" />
                </div>
                <div className="text-wrapper-10">View MIG Coins</div>
              </Link>
              <Link to={"/orders"} className="frame-13">
                <div className="group-17">
                  <img className="image-3" alt="Image" src="image-21.png" />
                </div>
                <div className="text-wrapper-11">Refill Medicines</div>
              </Link>
              <Link to={"/searchbox"} className="frame-13">
                <div className="group-18">
                  <img className="image-4" alt="Image" src="image-22.png" />
                </div>
                <div className="text-wrapper-11">Compare Medicine</div>
              </Link>
              <Link to={"/select-prescription"} className="frame-13">
                <div className="group-19">
                  <img className="image-4" alt="Image" src="image-22-1.png" />
                </div>
                <div className="text-wrapper-11">
                  Your <br />
                  Prescriptions
                </div>
              </Link>
            </div>
          </div>

          <div className="dashboard-item">
            <p className="text-wrapper-head">Our testimonials</p>
          </div>
          <div className="dashboard-item">
            <div
              className="slider"
              style={{
                transform: `translateX(${transformVX}px)`,
                transition: "transform 0.3s ease",
              }}
              {...swipeVHandlers}
            >
              {videoSlides.map((slide, index) => (
                <div
                  key={index}
                  className={`overlap-group-wrapper ${
                    index === currentVIndex ? "active" : ""
                  }`}
                  onClick={() => handleVideoClick(slide.videourl)} // Add onClick handler to open SweetAlert
                >
                  <div className="overlap-5">
                    <iframe
                      width="100%"
                      height="200px"
                      src={
                        slide.videourl +
                        "?controls=0&modestbranding=1&rel=0&autoplay=0"
                      }
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                </div>
              ))}
            </div>

            <div className="frame-11">
              {videoSlides.map((_, index) => (
                <div
                  key={index}
                  className={`rectangle-${
                    index === currentVIndex ? "23" : "24"
                  }`}
                  onClick={() => handleDotVClick(index)}
                />
              ))}
            </div>
          </div>
          <div className="dashboard-item">
            <p className="text-wrapper-head">Shop By Categories</p>
          </div>
          <div className="dashboard-item">
            <div className="frame-12">
              {categories.map((category, index) => (
                <div key={index} className="frame-131" onClick={()=>{handleCategories(category.category_name)}} >
                  <div className="group-17">
                    <img
                      className="image-2"
                      alt="Image"
                      src={category.category_image_url}
                    />
                  </div>
                  <div className="text-wrapper-10">{category.display_name}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <Navigation />
    </>
  );
};
