import React, { useState, useEffect, useCallback } from "react";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";
import Navigation from "../Dashboard/Navigation";
import Header from "../Dashboard/Header";
import {
  searchProducts,
  requestProduct,
  getProductDetails,
  getCouponDetails,
  getUser,
  addToCart,
} from "../../api/Api";
import Swal from "sweetalert2";
import DOMPurify from "dompurify";
import axios from "axios";
import { useCart, useCompare } from "../../api/stateContext";

export const SearchViewMedicine = () => {
  const [medicineDetails, setMedicineDetails] = useState({});
  const [medicineInfo, setMedicineInfo] = useState({});
  const [slides, setSlides] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [transformX, setTransformX] = useState(0);
  const [product, setProduct] = useState({});
  const [alternateProducts, setAlternateProducts] = useState([]);
  const [isRequestable, setIsRequestable] = useState(false);
  const [couponDetails, setCouponDetails] = useState([]);
  const [description, setDescription] = useState("");
  const { dispatch } = useCart();
  const { dispatchCompare } = useCompare();

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const suggestion = state?.product || {};

  const handleCompareClick = () => {
    navigate("/compare", { state: product });
  };

  const handleDotClick = useCallback((index) => {
    const distance = index * -300;
    setTransformX(distance);
    setCurrentIndex(index);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextIndex = (currentIndex + 1) % slides.length;
      handleDotClick(nextIndex);
    }, 3000);

    return () => clearInterval(intervalId);
  }, [currentIndex, slides.length, handleDotClick]);

  const fetchProductDetails = useCallback(async () => {
    try {
      const result = await getProductDetails(suggestion.product_id);
      if (result) {
        setProduct({
          product_id: result.product_id,
          name: result.productName,
          manufacturer: result.manufacturer || "Unknown Manufacturer",
          imageSrc: result.images[0]?.img
            ? `https://medingen.s3.us-east-1.amazonaws.com/products/${result.images[0].img}`
            : "rectangle-252.svg",
          genericName: result.saltName || "Unknown Salt Name",
          productDescription: result.productDescription || "No description",
          averagePrice: result.productPriceNew
            ? `${result.productPriceNew}`
            : "0",
          variants: [],
          product_available: result.product_available,
          composition: result.composition,
          mrp: result.productPriceOld ? `${result.productPriceOld}` : "0",
        });

        setMedicineDetails({
          drugComposition: result.composition || "Unknown",
          countryOfOrigin: "India",
          sellerInfo: result.marketed_by || "Seller Information",
          mrp: result.productPriceOld ? `${result.productPriceOld}` : "0",
          unitPrice: result.productPriceNew
            ? `${result.productPriceNew} per unit`
            : "0 per unit",
          unitsPerPack: result.packaging || "Unknown",
          deliveryTime: "5 - 10 days",
          prescriptionRequired: result.prescription_required ? "Yes" : "No",
          productDescription: result.productDescription || "No description",
        });

        // Load slide images
        const slideImages = result.images.map((image, index) => ({
          url: `https://medingen.s3.us-east-1.amazonaws.com/products/${image.img}`,
          name: `Image ${index + 1}`,
        }));
        setSlides(slideImages);

        if (result.productCoupon) {
          const coupons = result.productCoupon.split(",");
          fetchCouponDetails(coupons);
        }

        // Load description
        const desc = await axios.get(
          `https://medingen.s3.us-east-1.amazonaws.com/product_description/${result.productDescription}`
        );
        setDescription(DOMPurify.sanitize(desc.data));
      }
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  }, [suggestion.product_id]);

  const fetchAlternateProducts = useCallback(async () => {
    try {
      const query = `composition='${suggestion.composition}' AND rc=1`;
      const result = await searchProducts("", 1, { query });
      if (result && result.results) {
        console.log("Now Alternate products:", suggestion.averagePrice);
        const updatedProducts = result.results.map((prod) => ({
          originalPrice: prod.product_pricing_old,
          discountedPrice: prod.product_pricing_new,
          discount: Math.round(
            ((parseFloat(suggestion.averagePrice) -
              parseFloat(prod.product_pricing_new)) /
              parseFloat(suggestion.averagePrice)) *
              100
          ),
          imageUrl: prod.first_image_url,
          name: prod.product_name,
          manufacturer: prod.manufacturer,
        }));
        console.log("Alternate products:", updatedProducts);
        setAlternateProducts(updatedProducts);
      }
    } catch (error) {
      console.error("Error fetching alternate products:", error);
    }
  }, [suggestion.composition, product.averagePrice]);

  useEffect(() => {
    fetchProductDetails();
    fetchAlternateProducts();

    if (!suggestion.product_available && suggestion.product_request) {
      setIsRequestable(suggestion.product_request);
    }
  }, [
    fetchProductDetails,
    fetchAlternateProducts,
    suggestion.product_available,
    suggestion.product_request,
  ]);

  const fetchCouponDetails = async (coupons) => {
    try {
      const couponPromises = coupons.map((coupon) => getCouponDetails(coupon));
      const couponResponses = await Promise.all(couponPromises);
      setCouponDetails(couponResponses);
    } catch (error) {
      console.error("Error fetching coupon details:", error);
    }
  };

  const compareProduct = () => {
    dispatchCompare({
      type: "ADD_TO_COMPARE",
      payload: product,
    });
    navigate("/compare", { state: product });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleRequest = async () => {
    const user = getUser();
    const isLoggedIn = user.isLoggedIn;
    if (isLoggedIn) {
      try {
        await requestProduct(suggestion.product_id, navigate);
        Swal.fire({
          title: "Request Sent",
          text: "Your request has been sent successfully. We will notify you once the product is available.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error("Error requesting product:", error);
      }
    } else {
      navigate("/login");
    }
  };

  const handleCart = async () => {
    // check user login
    const user = getUser();
    const isLoggedIn = user.isLoggedIn;
    if (!isLoggedIn) {
      navigate("/login");
      return;
    }

    // Prompt the user to enter the quantity
    const { value: quantity } = await Swal.fire({
      title: "Enter Quantity",
      input: "number",
      inputAttributes: {
        min: 1,
        step: 1,
      },
      inputValue: 1, // Default value
      showCancelButton: true,
      confirmButtonText: "Add to Cart",
      cancelButtonText: "Cancel",
      icon: "question",
    });

    // Check if the user entered a valid quantity and did not cancel
    if (quantity && quantity > 0) {
      try {
        Swal.showLoading(); // Show loading spinner
        // Call the addToCart API with the entered quantity
        const result = await addToCart(
          suggestion.product_id,
          quantity,
          navigate
        );
        let cartItems = result.cart_items.split(";");
        // remove empty strings
        console.log(cartItems);
        cartItems = cartItems.filter((item) => item !== "");

        dispatch({ type: "UPDATE_COUNT", payload: cartItems.length });

        // Show success alert after the API call
        Swal.fire({
          title: "Product Added",
          text: "Product has been added to your cart.",
          icon: "success",
          confirmButtonText: "OK",
        });
      } catch (error) {
        console.error("Error adding product to cart:", error);
        // Handle any errors during the API call
        Swal.fire({
          title: "Error",
          text: "There was an error adding the product to your cart.",
          icon: "error",
          confirmButtonText: "OK",
        });
      }
    } else {
      // Show a warning if the input was canceled or invalid
      Swal.fire({
        title: "Invalid Quantity",
        text: "Please enter a valid quantity.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  };

  return (
    <>
      <div className="search-view-medicine">
        <Header />

        <div className="screen-view">
          <div className="screen-view-item">
            <div className="frame-wrapper">
              <div className="frame-10">
                <div className="frame-11">
                  <div className="text-wrapper-9">{product.name}</div>
                  <div className="text-wrapper-10">
                    {product.manufacturer === "Unknown Manufacturer"
                      ? ""
                      : "By " + product.manufacturer}
                  </div>
                  <p className="contains-PARACETAMOL">
                    <span className="span">
                      Contains: {product.genericName}
                    </span>
                  </p>
                </div>
                <div className="frame-12">
                  <div className="frame-13">
                    <div className="frame-141">
                      <div className="frame-151">
                        <div className="text-wrapper-11">
                          {product.averagePrice}
                        </div>
                      </div>
                      <div className="frame-151">
                        <div className="text-wrapper-12">Our Price</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="screen-view-item">
            <div className="group-3">
              <div className="frame-16">
                <div
                  className="slider"
                  style={{
                    transform: `translateX(${transformX}px)`,
                    transition: "transform 0.3s ease",
                  }}
                >
                  {slides.map((slide, index) => (
                    <div key={index} className="rectangle-2">
                      <img className="slideimg" src={slide.url} alt="slide" />
                    </div>
                  ))}
                </div>
              </div>
              <br />
              <br />
              <div className="frame-17">
                {slides.length > 1 &&
                  slides.map((_, index) => (
                    <div
                      key={index}
                      className={`rectangle-${
                        currentIndex === index ? "3" : "4"
                      }`}
                      onClick={() => handleDotClick(index)}
                    />
                  ))}
              </div>
            </div>
          </div>

          <div className="frame-25">
            {product.product_available ? (
              <div className="add-to-cart" onClick={handleCart}>
                <div className="add-button">Add to cart</div>
              </div>
            ) : (
              <></>
            )}
            {isRequestable && (
              <div className="add-to-cart">
                <div className="add-button" onClick={handleRequest}>
                  Request Product
                </div>
              </div>
            )}

            <div className="add-to-cart">
              <div className="add-button" onClick={compareProduct}>
                Add to Compare
              </div>
            </div>
          </div>

          <div className="screen-view-item">
            <div className="frame-item">
              <div className="frame-18">
                {couponDetails.map((coupon) => (
                  <div className="frame-19" key={coupon.coupon_code}>
                    <div className="FLAT-off">{coupon.coupon_text}</div>
                    <div className="text-wrapper-13">
                      on bills above {coupon.minimum_order_value}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="frame-20">
              <div className="frame-21">
                <div className="frame-22">
                  <div className="text-wrapper-141">Medicine Details</div>
                </div>
                <div className="frame-23">
                  <p className="drug-composition">
                    Drug Composition: {medicineDetails.drugComposition}
                  </p>
                  <p className="p">
                    Country of Origin: {medicineDetails.countryOfOrigin}
                  </p>
                  <div className="frame-24">
                    <div className="text-wrapper-151">
                      {medicineDetails.sellerInfo}
                    </div>
                    <img className="vector" alt="Vector" src="vector-3.svg" />
                  </div>
                  <p className="MRP-rs-rs-per">
                    <span className="text-wrapper-16">
                      MRP Rs. {medicineDetails.mrp}
                    </span>
                  </p>
                  <div className="text-wrapper-19">
                    Units Per Pack: {medicineDetails.unitsPerPack}
                  </div>
                  <div className="text-wrapper-19">
                    Delivery Time: {medicineDetails.deliveryTime}
                  </div>
                  <div className="text-wrapper-19">
                    Prescription Required:{" "}
                    {medicineDetails.prescriptionRequired}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="screen-view-item">
            <div className="div-head">
              <div className="text-wrapper-3">Product Description</div>
              {description && (
                <div
                  className="description"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </div>
          </div>
          {alternateProducts.length > 0 && (
            <div className="screen-view-item">
              <div className="frame-4">
                <div className="text-wrapper-3">
                  Alternative medicine with the same exact composition “
                  {product.composition}”
                </div>
                <div className="overlap-3">
                  <div className="product-list">
                    {alternateProducts.map((altProduct, index) => (
                      <div className="product-item" key={index}>
                        <img
                          className="product-image"
                          alt="Alternate Product"
                          src={
                            "https://medingen.s3.us-east-1.amazonaws.com/products/" +
                            altProduct.imageUrl
                          }
                        />
                        <div className="product-info">
                          <div className="product-name">{altProduct.name}</div>
                          <div className="product-manufacturer">
                            {altProduct.manufacturer}
                          </div>
                          <div className="price-info">
                            {/* <div className="original-price">
                            Rs. {altProduct.originalPrice}
                          </div> */}
                            <div className="discounted-price">
                              Rs. {altProduct.discountedPrice}
                            </div>
                            {altProduct.discount > 0 && (
                              <div className="price-tag">
                                <div className="price-discount">
                                  <div className="discount">
                                    {altProduct.discount}% less Price
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="add-to-cart">
                          <div className="add-button">View</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <Navigation />
      <div className="frame-27" onClick={handleCompareClick}>
        <img className="img" alt="Compare" src="compare.svg" />
        <div className="text-wrapper-24">Compare</div>
      </div>
    </>
  );
};
