import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'; // Ensure Swal is imported for consistent use with API feedback
import {
  addAddress,
  updateAddress,
  deleteAddress,
  selectAddress,
} from '../../api/Api'; // Import API functions from the correct path
import "./style.css";
import Header from '../Dashboard/Header';
import Navigation from '../Dashboard/Navigation';

export const AddressNew = () => {
  const location = useLocation();
  const navigate = useNavigate();

  // Extract address data from location state
  const addressData = location.state || {};
  const { id, type, name, address1, pincode, state: initialState, editing } = addressData;

  // State for form fields
  const [formType, setFormType] = useState(type || '');
  const [formName, setFormName] = useState(name || '');
  const [formAddress1, setFormAddress1] = useState(address1 || '');
  const [formPincode, setFormPincode] = useState(pincode || '');
  const [formState, setFormState] = useState(initialState || '');

  // Handle state change
  const handleStateChange = (event) => {
    setFormState(event.target.value);
  };

  // Validate form fields
  const validateForm = () => {
    return formName && formAddress1 && formPincode && formState && formType;
  };

  // Handle form submission
  const handleSave = async () => {
    if (!validateForm()) {
      // Show SweetAlert if any field is empty
      await Swal.fire({
        title: 'Missing Information',
        text: 'Please fill out all fields before saving.',
        icon: 'info',
        confirmButtonText: 'OK',
      });
      return;
    }

    const updatedAddress = {
      id,
      type: formType,
      name: formName,
      address1: formAddress1,
      pincode: formPincode,
      state: formState,
    };

    try {
      if (editing) {
        // If editing, update the existing address
        await updateAddress(id, updatedAddress, navigate);
      } else {
        // If not editing, add a new address
        await addAddress(updatedAddress, navigate);
      }
      
      // Redirect to /savedaddress after successful update
      navigate('/savedaddress');
    } catch (error) {
      // Handle API error
      await Swal.fire({
        title: 'Error',
        text: 'Something went wrong. Please try again later.',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }
  };

  // Handle delete address
  const handleDelete = async () => {
    // Confirm delete action with the user
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: 'This will delete the address permanently.',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });

    if (result.isConfirmed) {
      // Proceed to delete the address if the user confirms
      await deleteAddress(id, navigate);
    }
  };

  // Handle select address
  const handleSelect = async () => {
    await selectAddress(id, navigate);
  };

  return (
    <>
    <Header />

<div className="address-new">
      <br />
      <div className="header">
        <div className="header-title">
          {editing ? 'Edit Address' : 'Enter New Address'}
        </div>
      </div>
      <br />

      <div className="nickname-section">
        <div className="radio-group">
          <label className="radio-label">
            <input
              type="radio"
              name="address"
              value="home"
              checked={formType === 'home'}
              onChange={() => setFormType('home')}
              className="radio-input"
            />
            <div className="radio-circle"></div>
            <div className="radio-text">Home</div>
          </label>
          <label className="radio-label">
            <input
              type="radio"
              name="address"
              value="office"
              checked={formType === 'office'}
              onChange={() => setFormType('office')}
              className="radio-input"
            />
            <div className="radio-circle"></div>
            <div className="radio-text">Office</div>
          </label>
          <label className="radio-label">
            <input
              type="radio"
              name="address"
              value="other"
              checked={formType === 'other'}
              onChange={() => setFormType('other')}
              className="radio-input"
            />
            <div className="radio-circle"></div>
            <div className="radio-text">Other</div>
          </label>
        </div>
      </div>
      <br />
      <div className="input-group">
        <label className="input-label" htmlFor="name">Full Name</label>
        <input
          type="text"
          id="name"
          className="input-field"
          value={formName}
          onChange={(e) => setFormName(e.target.value)}
        />
      </div>

      <div className="input-group">
        <label className="input-label" htmlFor="address1">Address</label>
        <input
          type="text"
          id="address1"
          className="input-field"
          value={formAddress1}
          onChange={(e) => setFormAddress1(e.target.value)}
        />
      </div>

      <div className="input-group">
        <label className="input-label" htmlFor="pincode">Pincode</label>
        <input
          type="text"
          id="pincode"
          className="input-field"
          value={formPincode}
          onChange={(e) => setFormPincode(e.target.value)}
        />
      </div>

      <div className="input-group">
        <label className="input-label" htmlFor="state">State</label>
        <input
          type="text"
          id="state"
          className="input-field"
          value={formState}
          onChange={handleStateChange}
        />
      </div>

      <div className="action-buttons">
        <button onClick={handleSave} className="action-button save-button">
          Save Address
        </button>
        {editing && (
          <>
            <button onClick={handleDelete} className="action-button delete-button">
              Delete Address
            </button>
            <button onClick={handleSelect} className="action-button select-button">
              Select Address
            </button>
          </>
        )}
      </div>
      <div className='margin-bottom'></div>
    </div>
    
    <Navigation />
    </>
  );
};
