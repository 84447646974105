import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import Header from "../Dashboard/Header";

// Sample data (for demonstration purposes)
const sampleOffers = [
  {
    id: 1,
    title: "Biggest Ever Sale is happening on National Pharmacist Day",
    description: "Buy all your medicine at 15% flat discount and also free of all delivery charges",
    image: "rectangle-264.png",
    linkText: "View Offer Now",
  },
  // Add more offer objects here as needed
];

export const OffersView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  
  // Retrieve the offer from state or use fallback
  const { offer } = location.state || sampleOffers[0]; // Fallback to first offer if none found
  
  if (!offer) {
    return <div>Offer not found</div>;
  }

  const handleBackClick = () => {
    navigate('/offers'); // Navigate back to the offers page
  };

  const handleGetStartedClick = () => {
    navigate('/dashboard'); // Navigate to the dashboard page
  };

  return (
    <div className="offers-view">
      <Header />
      <div className="content">

      <img className="rectangle" alt="Offer" src={offer.image} />
      <div className="frame">
        <div className="div">
          <p className="text-wrapper">{offer.title}</p>
          <p className="buy-all-your">{offer.description}</p>
        </div>
      </div>
      <div className="frame-wrapper">
        <div className="frame-5" onClick={handleGetStartedClick}>
          <div className="text-wrapper-4">Get Started</div>
          <img className="frame-6" alt="Arrow" src="frame-3016860.svg" />
        </div>
      </div>
      </div>
    </div>
  );
};
