import React, { useState, useEffect } from "react";
import "./style.css";
import NavigationHome from "../Dashboard/NavigationHome";
import Header from "../Dashboard/Header";
import Navigation from "../Dashboard/Navigation";
import { addToCart, getOrders, getUser, updateOrderAutoRefill } from "../../api/Api"; // API function
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export const Orders = () => {
  const [searchInput, setSearchInput] = useState("");
  const [orders, setOrders] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();


  // Fetch orders from API
  useEffect(() => {
   
   // check login or else redirect to search medicine page
   const user = getUser();
   if (!user.customer_id) {
     navigate("/login");
     return
   }
    const fetchOrders = async () => {
      try {
        const response = await getOrders(page, searchInput); // Pass search query to API
        setOrders(response.orders);
        setTotalPages(response.total_pages);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Failed to fetch orders",
        });
      }
    };
    fetchOrders();
  }, [page, searchInput]); // Trigger API call when page or searchInput changes

  const handleTrack = (cart_id) => {
    console.log("Tracking order:", cart_id);
    navigate("/order-track", { state: { cart_id } });
  };

  const handleReorder = (cartItems) => {
    console.log("Reordering items:", cartItems);
    let failedItems = [];
    Swal.showLoading();
    for (let i = 0; i < cartItems.length; i++) {
      const [productId, quantity] = cartItems[i].split(":");
      // Add product to cart
      try {
        // console.log("Adding to cart", productId, quantity);
        const result = addToCart(productId, quantity, navigate);
      } catch (error) {
        failedItems = [...failedItems, cartItems[i]];
      }
    }
    if (failedItems.length > 0) {
      Swal.fire({
        icon: "warning",
        title: "Re-order",
        text: "Some items could not be added to cart. Add those manually",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/order-progress");
      });
    } else {
      Swal.fire({
        icon: "success",
        title: "Re-order",
        text: "All items added to cart successfully",
        confirmButtonText: "OK",
      }).then(() => {
        navigate("/order-progress");
      });
    }
  };


  return (
    <>
      <Header title="Orders" />
      <div className="orders">
        <div className="frame-3">
          <div className="div-wrapper">
            <div className="frame-4">
              <div className="frame-5">
                <img className="search" alt="Search" src="search.svg" />
                <input
                  className="text-input"
                  type="text"
                  placeholder="Search orders"
                  value={searchInput}
                  onChange={(e) => setSearchInput(e.target.value)} // Update search input
                />
              </div>
              <img
                className="img"
                alt="Fluent mic"
                src="fluent-mic-20-regular.svg"
              />
            </div>
          </div>
        </div>

        {orders.length === 0 ? (
          <div className="no-orders">
            <p>Place your first order and enjoy the offers and cashback</p>
          </div>
        ) : (
          <>
            <div className="orders-list">
              {orders.map((order) => (
                <OrderCard
                  key={order.id}
                  order={order}
                  handleReorder={handleReorder}
                  handleTrack={handleTrack}
                />
              ))}
            </div>

            {totalPages > 1 && (
              <div className="pagination">
                <button
                  className="prev-button"
                  onClick={() => setPage(page - 1)}
                  disabled={page <= 1}
                >
                  Previous
                </button>
                <span>{`Page ${page} of ${totalPages}`}</span>
                <button
                  className="next-button"
                  onClick={() => setPage(page + 1)}
                  disabled={page >= totalPages}
                >
                  Next
                </button>
              </div>
            )}
          </>
        )}
      </div>
      <Navigation />
    </>
  );
};const OrderCard = ({ order, handleReorder, handleTrack }) => {

  const [autoRefill, setAutoRefill] = useState(order.auto_refill);

  let cartItems = order.product_ids_qty.split(";");
  // Remove empty strings
  cartItems = cartItems.filter((item) => item !== "");

  // Use a Map to combine quantities of the same product_id
  const productMap = new Map();

  cartItems.forEach((item) => {
    const [productId, quantity] = item.split(":");
    const currentQuantity = productMap.get(productId) || 0;
    productMap.set(productId, currentQuantity + parseInt(quantity, 10));
  });

  // Convert the Map back to an array format like "product_id:total_quantity"
  const combinedCartItems = Array.from(productMap.entries()).map(
    ([productId, totalQuantity]) => `${productId}:${totalQuantity}`
  );

  const cartItemCount = combinedCartItems.length;

  // date from format "2021-09-01 00:00:00" to "01 Sep 2021 12:00 AM"
  const date = new Date(order.date);
  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  order.date = date.toLocaleDateString("en-IN", options);

  const mapping = {
    "active": "In cart",
    "confirm": "Order Confirmed",
    "pending_confirm": "Order Placed (pending confirmation)",
    "payment": "In-Transit",
    "delivered": "Delivered",
  };

  const handleRefill = async (cart_id, checked) => {
    console.log("Auto-Refill:", cart_id, checked);
    const response = await updateOrderAutoRefill(cart_id, checked);
    
    if (response.status === 200) {
      if(checked){

        Swal.fire({
          title: 'Success!',
          text: 'Order set to auto-refill. We will contact you before processing the order.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then(() => {
          setAutoRefill(checked)
        }
        );
      }
        else{
          Swal.fire({
            title: 'Success!',
            text: 'Order auto-refill cancelled.',
            icon: 'info',
            confirmButtonText: 'OK'
          }).then(() => {
            setAutoRefill(checked)
          }
          );
        }
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'Failed to update order auto-refill. Please try again.',
        icon: 'error',
        confirmButtonText: 'Try again'
      });
    }
  }

  return (
    <div className="order-card">
      <div className="order-status">
        <img className="tick" alt="Tick" src="tick.svg" />
        <div className="label-wrapper">
          <div className="label">{mapping[order.status]}</div>
        </div>
      </div>
      <div className="order-info">
        <div className="frame-7">
          <div className="label-2">Order ID</div>
          <div className="label-3">{order.id}</div>
        </div>
        <div className="frame-7">
          <div className="label-2">No. of items</div>
          <div className="label-3">{cartItemCount}</div>
        </div>
        <div className="frame-7">
          <div className="label-2">Order date</div>
          <p className="label-3">{order.date}</p>
        </div>
      </div>
      <div className="order-actions">
      <div className="refill-toggle">
        <span className="refill-text">Auto-Refill</span>

          <input
            type="checkbox"
            id={`refill-toggle-${order.id}`}
            className="refill-checkbox"
            onChange={(e) => handleRefill(order.id, e.target.checked)}
            checked={autoRefill}
          />
          <label htmlFor={`refill-toggle-${order.id}`} className="refill-slider"></label>
        </div>
        </div>
        <div className="order-actions">

        <div
          className="label-4"
          onClick={() => {
            handleReorder(combinedCartItems);
          }}
        >
          Reorder
        </div>
        <div className="track-order" onClick={() => handleTrack(order.id)}>
          <div className="text-wrapper-4">Track your order</div>
        </div>
     
      </div>
    </div>
  );
};
