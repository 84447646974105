import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';
import "./App.css";
import { CSSTransition, TransitionGroup } from 'react-transition-group';

// Import all components
import { Splash1 } from "./screens/Splash1/Splash1";
import { Dashboard } from "./screens/Dashboard/Dashboard";
import { SavedAddress } from "./screens/SavedAddress/SavedAddress";
import { AddressNew } from "./screens/AddressNew/AddressNew";
import { UploadPrescription } from "./screens/UploadPrescription/UploadPrescription";
import { SelectPrescription } from "./screens/SelectPrescription/SelectPrescription";
import { OrderPlacedSuccess } from "./screens/OrderPlacedSuccess/OrderPlacedSuccess";
import { OrderConfirmed } from "./screens/OrderConfirmed/OrderConfirmed";
import { Splash3 } from "./screens/Splash3/Splash3";
import { Login1 } from "./screens/Login1/Login1";
import { Login2 } from "./screens/Login2/Login2";
import { EnterPassword } from "./screens/EnterPassword/EnterPassword";
import { ChangePassword } from "./screens/ChangePassword/ChangePassword";
import { CreateProfile } from "./screens/CreateProfile/CreateProfile";
import { Createsuccess } from "./screens/CreateSuccess/CreateSuccess";
import { SearchBox } from "./screens/SearchBox/SearchBox";
import { SearchResults } from "./screens/SearchResults/SearchResults";
import { SearchViewMedicine } from "./screens/SearchViewMedicine/SearchViewMedicine";
import { CompareView } from "./screens/CompareView/CompareView";
import { Offers } from "./screens/Offers/Offers";
import { OffersView } from "./screens/OffersView/OffersView";
import { Rewards } from "./screens/Rewards/Rewards";
import { RewardsView } from "./screens/RewardsView/RewardsView";
import { RewardsViewMIG } from "./screens/RewardsViewMig/RewardsViewMIG";
import { OrderProgress } from "./screens/OrderProgressConfirmation/OrderProgressConfirmation";
import { OrderPayment } from "./screens/OrderPayment/OrderPayment";
import { Orders } from "./screens/Orders/Orders";
import { OrdersTrack } from "./screens/OrdersTrack/OrdersTrack";
import { Notifications } from "./screens/Notifications/Notifications";
import { ProfileView } from "./screens/ProfileView/ProfileView";
import { CreatePassword } from "./screens/CreatePassword/CreatePassword";
import { getCartCount, getUser } from "./api/Api";
import { CapturePrescription } from "./screens/CapturePrescription/CapturePrescription";

// Functional component to show the "Feature Coming Soon" alert
const FeatureComingSoon = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Swal.fire({
      title: 'Feature Coming Soon',
      text: 'This feature is currently under development. Please check back later!',
      icon: 'info',
      confirmButtonText: 'OK',
    }).then(() => {
      navigate(-1);
    });
  }, [navigate]);

  return null;
};

// Main App component
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<PageWrapper />} />
      </Routes>
    </Router>
  );
};

// PageWrapper component to handle location and transitions
const PageWrapper = () => {
  const location = useLocation();

  const [showSplash1, setShowSplash1] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);
  const [isAppInstalled, setIsAppInstalled] = useState(false);

  useEffect(() => {
    const app = document.querySelector(".App");
    const scaleFactorX = 1;
    const scaleFactorY = 1;
    app.style.transform = `scale(${scaleFactorX}, ${scaleFactorY})`;

    const beforeInstallPromptHandler = (e) => {
      e.preventDefault();
    };

    const appInstalledHandler = () => {
      setIsAppInstalled(true);
    };

    window.addEventListener('beforeinstallprompt', beforeInstallPromptHandler);
    window.addEventListener('appinstalled', appInstalledHandler);

    if (window.matchMedia('(display-mode: standalone)').matches) {
      setIsAppInstalled(true);
    }
    
    // setShowSplash1(false);
    // setInitialLoad(false);

    Swal.fire({
      title: 'Welcome to Medingen App',
      text: 'This is a pilot study before the alpha version of the app is released to get feedback on the app. By participating in this, you are eligible for free MIG Coins which can be redeemed to buy medicines in our app. Click on the install now button shown above to install the app into your device to access this. This is be quick and secure and doesnt occupy much storage in your device',
      icon: 'info',
      confirmButtonText: 'Explore',
      showCancelButton: false,
      reverseButtons: true
    }).then((result) => {
      if (result.isConfirmed) {
        if (!isAppInstalled) {
          Swal.fire({
            title: 'Install the App',
            text: 'For a better experience, please install the app to your device. On Android, click the three dots in the top right corner and select "Add to Home screen". On iOS, tap the "Share" button and then "Add to Home Screen".',
            icon: 'info',
            showCancelButton: false,
            showConfirmButton: false
          });
        }else{
        setShowSplash1(false);
        setInitialLoad(false);
      }
      }
    });

    return () => {
      window.removeEventListener('beforeinstallprompt', beforeInstallPromptHandler);
      window.removeEventListener('appinstalled', appInstalledHandler);
    };
  }, [isAppInstalled]);

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.pathname}
      >
        <div className="App">
          {initialLoad && showSplash1 ? (<Splash1 />) : (
          <Routes location={location}>
            <Route path="/" element={<Splash3 />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/savedaddress" element={<SavedAddress />} />
            <Route path="/searchbox" element={<SearchBox />} />
            <Route path="/search-results" element={<SearchResults />} />
            <Route path="/search-view-medicine" element={<SearchViewMedicine />} />
            <Route path="/compare" element={<CompareView />} />
            <Route path="/offers" element={<Offers />} />
            <Route path="/notification" element={<Notifications />} />
            <Route path="/profile" element={<ProfileView />} />
            <Route path="/login" element={<Login1 />} />
            <Route path="/login2" element={<Login2 />} />
            <Route path="/create-profile" element={<CreateProfile />} />
            <Route path="/enterpassword" element={<EnterPassword />} />
            <Route path="/createpassword" element={<CreatePassword />} />
            <Route path="/addressnew" element={<AddressNew />} />
            <Route path="/upload-prescription" element={<UploadPrescription />} />
            <Route path="/view-offer" element={<OffersView />} />
            <Route path="/rewards" element={<Rewards />} />
            <Route path="/select-prescription" element={<SelectPrescription />} />
            <Route path="/place-order" element={<OrderPlacedSuccess />} />
            <Route path="/capture-prescription" element={<CapturePrescription />} />
            <Route path="/order-progress" element={<OrderProgress />} />
            <Route path="/order-payment" element={<OrderPayment />} />
            <Route path="/orders" element={<Orders />} />
            <Route path="*" element={<FeatureComingSoon />} />
          </Routes>
          )}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};

export default App;
