import React, { useState, useEffect, useRef } from "react";
import "./style.css";
import NavigationHome from "../Dashboard/NavigationHome";
import { searchProducts } from "../../api/Api";
import Navigation from "../Dashboard/Navigation";
import { Link, useLocation, useNavigate } from "react-router-dom";

export const SearchBox = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  const navigate = useNavigate();
  const searchInputRef = useRef(null); // Create a ref for the input element
  const location = useLocation();

  useEffect(() => {
    if(location.state?.category){
      setSearchText(location.state.category + ":");
    }
    // Focus on the search text box when the component loads
    searchInputRef.current.focus();
  }, []);


  useEffect(() => {
    // Trigger API call if the searchText length is greater than 2
    const fetchProducts = async () => {
      if (searchText.length > 2) {
        setLoading(true);
        setError("");

        try {
          const results = await searchProducts(searchText, currentPage);
          setFilteredSuggestions(results.results); // Update to match API structure
          setTotalPages(results.total_pages); // Directly use the total pages from the API
        } catch (error) {
          setError("Failed to fetch products. Please try again.");
        } finally {
          setLoading(false);
        }
      } else {
        setFilteredSuggestions([]);
      }
    };

    fetchProducts();
  }, [searchText, currentPage]);

  const handleChange = (event) => {
    setSearchText(event.target.value);
    setCurrentPage(1); // Reset to first page on new search
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    // Handle the selection of a suggestion
    console.log("Selected suggestion:", suggestion)
      navigate("/search-results", { state: { product: suggestion }, replace: false });
    
  };

  return (
    <div className="search-box">
      <div className="overlap">
        <div className="frame">
          <div className="overlap-group">
            <div className="group"></div>
            <div className="frame-2">
              <Link to="/dashboard">
              <img className="line-arrow-chevron" alt="Line arrow chevron" src="line-arrow-chevron-left.svg" />
              </Link>
              <div className="text-wrapper">Search</div>
            </div>
          </div>
        </div>
        <div className="div-wrapper">
          <div className="frame-wrapper">
            <div className="frame-3">
              <div className="frame-4">
                <div className="frame-5">
                  <input
                    type="text"
                    placeholder="Type a medicine name ..."
                    className="text-wrapper-2"
                    name="search_text"
                    value={searchText}
                    id="search_text"
                    onChange={handleChange}
                    ref={searchInputRef} // Attach the ref to the input element
                  />
                </div>
              </div>
            </div>
          </div>
          <img className="img-2" alt="Fluent mic" src="fluent-mic-20-regular.svg" />
        </div>
      </div>
      {(searchText.length > 2 ?
             (<>
      <div className="overlap-3" >
        <div className="frame-8">
          {loading ? (
            <div className="loader">Loading...</div>
          ) : error ? (
            <div className="error">{error}</div>
          ) : (
            <>
            {(filteredSuggestions.length === 0 && searchText.length > 2 ?
              <div className="text-wrapper-5">No products found for "{searchText}"</div>
              : null)  
            }
           
            {filteredSuggestions.length > 0 && (
              <div className="text-wrapper-5">Suggestions for "{searchText}"</div>
            )}
              {filteredSuggestions.map((suggestion) => (
                <div key={suggestion.product_id} className="suggestion" onClick={() =>{handleSelectSuggestion(suggestion) } } >
                  <div className="product-name">{suggestion.product_name}</div>
                </div>
              ))}
              {totalPages > 1 && (
                <div className="pagination">
                  <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                    Previous
                  </button>
                  <span>
                    Page {currentPage} of {totalPages}
                  </span>
                  <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                    Next
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      </>)
             : null)
            }
      <Navigation />
    </div>
  );
};
