import { React, useEffect, useState } from "react";
import "./style.css";
import Header from "../Dashboard/Header";
import { Link, useNavigate } from "react-router-dom";
import Navigation from "../Dashboard/Navigation";
import { CapturePrescription } from "../CapturePrescription/CapturePrescription";
import { SelectPrescription } from "../SelectPrescription/SelectPrescription";
import { getUser } from "../../api/Api";

export const UploadPrescription = ({ choosePrescription }) => {
  const [methodSelected, setMethodSelected] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    
   // check login or else redirect to search medicine page
   const user = getUser();
   if (!user.customer_id) {
     navigate("/login");
     return
   }
  }, [navigate]);

  const handleUploadButtonClick = () => {
    navigate("/capture-prescription", { state: { isUpload: true } });
  };

  const handleOptionClick = (componentName) => {
    setMethodSelected(componentName);
  };

  return (
    <>
      {methodSelected === "upload-prescription" ? (
        <CapturePrescription choosePrescription={choosePrescription} isUpload={true} />
      ) : methodSelected === "capture-prescription" ? (
        <CapturePrescription  choosePrescription={choosePrescription} isUpload={false} />
      ) : methodSelected === "select-prescription" ? (
        <SelectPrescription  choosePrescription={choosePrescription} isUpload={false} />
      ) : (
        <>
          <div className="upload-prescription">
            <Header
              title={
                choosePrescription
                  ? "Choose Prescription"
                  : "Upload Prescription"
              }
            />
            <div className="upload-section">
              <div className="options-section">
                <div
                  onClick={() => handleOptionClick("capture-prescription")}
                  className="option"
                >
                  <div className="icon-container">
                    <img className="icon" alt="Camera" src="camera-1.png" />
                  </div>
                  <div className="option-label">Take a picture</div>
                </div>
                <div
                  onClick={() => handleOptionClick("upload-prescription")}
                  className="option"
                >
                  <div className="icon-container">
                    <img className="icon" alt="Gallery" src="gallery-1.png" />
                  </div>
                  <div className="option-label">Select from Gallery</div>
                </div>
                <div
                  onClick={() => handleOptionClick("select-prescription")}
                  className="option"
                >
                  <div className="icon-container">
                    <img
                      className="icon"
                      alt="Prescription"
                      src="prescription-1-1.png"
                    />
                  </div>
                  <div className="option-label">
                    Select from your Prescriptions
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Navigation />
        </>
      )}
    </>
  );
};
