import React, { createContext, useContext, useReducer } from 'react';

// Create the contexts
const CartContext = createContext();
const CompareContext = createContext();

// Define actions for both contexts
const UPDATE_COUNT = 'UPDATE_COUNT';
const ADD_TO_COMPARE = 'ADD_TO_COMPARE';
const REMOVE_FROM_COMPARE = 'REMOVE_FROM_COMPARE';

// Initial state for cart
const initialCartState = {
  itemCount: 0,
};

// Initial state for compare
const initialCompareState = {
  compareProducts: [], // List of product objects
};

// Reducer function to handle state changes for cart
const cartReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_COUNT:
      return {
        ...state,
        itemCount: action.payload,
      };
    default:
      return state;
  }
};

// Reducer function to handle state changes for compare
const compareReducer = (state, action) => {
  switch (action.type) {
    case ADD_TO_COMPARE:
      return {
        ...state,
        compareProducts: [...state.compareProducts, action.payload],
      };
    case REMOVE_FROM_COMPARE:
      return {
        ...state,
        compareProducts: state.compareProducts.filter(
          product => product.id !== action.payload.id
        ),
      };
    default:
      return state;
  }
};

// Cart provider component
export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(cartReducer, initialCartState);

  return (
    <CartContext.Provider value={{ itemCount: state.itemCount, dispatch }}>
      {children}
    </CartContext.Provider>
  );
};

// Compare provider component
export const CompareProvider = ({ children }) => {
  const [state, dispatchCompare] = useReducer(compareReducer, initialCompareState);

  return (
    <CompareContext.Provider value={{ compareProducts: state.compareProducts, dispatchCompare }}>
      {children}
    </CompareContext.Provider>
  );
};

// Custom hook to use the cart context
export const useCart = () => useContext(CartContext);

// Custom hook to use the compare context
export const useCompare = () => useContext(CompareContext);
