import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import './style.css';
import Navigation from '../Dashboard/Navigation';
import Header from '../Dashboard/Header';
import { getUser, getProfileData, handleSignOut, updateProfileData, uploadFile, sendOTP } from '../../api/Api'; // Import updateProfileData
import { Link, useNavigate } from 'react-router-dom'; // Assuming you're using react-router-dom
import Cookies from 'js-cookie';

export const ProfileView = () => {
  const [profile, setProfile] = useState(null); // Initialize as null
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is logged in
    const user = getUser();

    if (!user.isLoggedIn) {
      navigate('/login'); // Redirect to login pagetr
      return;
    } else {
      if(!Cookies.get('customer_name')) {
        navigate('/create-profile');
      }
      // Show SweetAlert loading progress bar
      Swal.fire({
        title: 'Loading...',
        text: 'Fetching profile data, please wait...',
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Fetch profile data (mocked API call)
      getProfileData()
        .then((data) => {
          setProfile(data);
          Swal.close(); // Close the SweetAlert progress bar
        })
        .catch((error) => {
          Swal.fire('Error', 'Failed to load profile data', 'error');
          console.error(error);
        });
    }
  }, [navigate]);

  const changepass = () => {
    sendOTP(profile.phone, navigate);
    navigate("/login2", { state: { phoneNumber: profile.phone } });
  };

 // Helper functions for validation
const validateEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validateDOB = (dob) => {
  const dobRegex = /^\d{2}-\d{2}-\d{4}$/; // Assuming format DD-MM-YYYY
  return dobRegex.test(dob);
};

const validateCustomerName = (name) => {
  const nameRegex = /^[a-zA-Z\s]+$/; // Alphanumeric characters and spaces
  return nameRegex.test(name);
};

// Handle profile updates
const handleProfileUpdate = (field, currentValue, text) => {

  Swal.fire({
    title: `Update Details`,
    text: text,
    input: 'text',
    inputValue: currentValue,
    showCancelButton: true,
    confirmButtonText: 'Update',
    cancelButtonText: 'Close',
    showLoaderOnConfirm: true,
    preConfirm: (newValue) => {
      if (newValue !== currentValue) {
        // Validate email format
        if (field === 'email' && !validateEmail(newValue)) {
          Swal.showValidationMessage('Invalid email format');
          return false;
        }
        // Validate date of birth format
        if (field === 'dob' && !validateDOB(newValue)) {
          Swal.showValidationMessage('Invalid date of birth format (DD-MM-YYYY)');
          return false;
        }
        // Validate customer name format
        if (field === 'customer_name' && !validateCustomerName(newValue)) {
          Swal.showValidationMessage('Customer name can only contain alphabetic characters and spaces');
          return false;
        }
        // Proceed with API call if validation passes
        return updateProfileData({ [field]: newValue })
          .then(() => {
            if (field === 'customer_name') {
              Cookies.set('customer_name', newValue);
            }
            return getProfileData();
          })
          .then((updatedProfile) => {
            setProfile(updatedProfile);
          })
          .catch((error) => {
            Swal.fire('Error', 'Failed to update profile data', 'error');
            console.error(error);
          });
      }
    },
    allowOutsideClick: () => !Swal.isLoading(),
  });
};


  // Handle logout
  const handleLogout = () => {
    handleSignOut(); // Call the sign out function
    navigate('/login'); // Redirect to login page after logout
  };

  // Handle profile picture change
  const handleProfilePictureChange = () => {

    Swal.fire({
      title: 'Upload a new profile picture',
      input: 'file',
      inputAttributes: {
        accept: 'image/*',
        'aria-label': 'Upload profile picture',
      },
      confirmButtonText: 'Upload',
      showCancelButton: true,
      cancelButtonText: 'Cancel',
      preConfirm: (file) => {
        if (!file) {
          Swal.showValidationMessage('No file selected');
          return false;
        }
        Swal.showLoading(); // Show loading progress bar
        const filename = uploadFile(file, "profilepic"); // Call the API to upload the file
        return file;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        const file = result.value;
        const reader = new FileReader();
        
        reader.onload = (e) => {
          setProfile((prevProfile) => ({
            ...prevProfile,
            profilePicture: e.target.result, // Set the image data URL
          }));
        };

        reader.readAsDataURL(file); // Convert file to data URL
      }
    });
  };

  if (!profile) {
    return null; // Render nothing or a loader if profile data is not yet available
  }

  return (
    <>
      <Header />

      <div className={`profile-view ${profile.theme}`}>

        <div className="profile-info">
          <img
            className="profile-picture"
            alt="Profile"
            src={profile.profilePicture}
            onClick={handleProfilePictureChange} // Add onClick handler
          />
          <div className="profile-details">
            <div className="profile-name">{profile.name}</div>
            <div className="edit-profile-link" onClick={() => handleProfileUpdate('customer_name', profile.name, 'Update your name')}>Edit</div>
          </div>
          <div className="logout-section">
            <div className="logout-button" onClick={handleLogout}>
              <div className="logout-text">Log-out</div>
              <img className="logout-icon" alt="Logout Icon" src="logout-1.svg" />
            </div>
          </div>
        </div>

        <div className="coins-section">
          <div className="coins-title">Your MIG Coins</div>
          <div className="coins-value">{profile.coins}</div>
        </div>

        <div className="details-section">
          <div className="details-item">
            <div className="details-label">Mail ID</div>
            <div className="details-value">
              <div className="details-value-text">{profile.email}</div>
            </div>
            <div className="change-link" onClick={() => handleProfileUpdate('email', profile.email, 'Update your email')}>Change</div>

          </div>
          <div className="details-item">
            <div className="details-label">Date of Birth</div>
            <div className="details-value">{profile.dob}</div>
            <div className="change-link" onClick={() => handleProfileUpdate('dob', profile.dob, 'Update your date of birth (dd-mm-yyyy) ')}>Change</div>
          </div>
          <div className="details-item">
            <div className="details-label">Phone Number</div>
            <div className="details-value">{profile.phone}</div>
          </div>
          <Link to="/savedaddress" className="details-item">
            <div className="details-label">Change Address</div>
            <img className="back-arrow" alt="Change Address Arrow" src="line-arrow-chevron-left-1.svg" />
          </Link>
          <div className="details-item" onClick={changepass} >
            <div className="details-label">Change Password</div>
            <img className="back-arrow" alt="Change Password Arrow" src="line-arrow-chevron-left-1.svg" />
          </div>
          <Link to="/orders" className="details-item">
            <div className="details-label">Your orders</div>
            <img className="back-arrow" alt="Your Orders Arrow" src="line-arrow-chevron-left-1.svg" />
          </Link>
          <Link to="/upload-prescription" className="details-item">
            <div className="details-label">Prescriptions</div>
            <img className="back-arrow" alt="Prescriptions" src="line-arrow-chevron-left-1.svg" />
          </Link>
          <div className="support-contact">
            <div className="details-label">Contact our support</div>
            <div className="support-info">
              <div className="details-label">Toll free number (pilot) </div>
              <div className="details-label">+91 6381975763</div>
            </div>
          </div>
        </div>
        <div className='margin-72'></div>
      </div>
      <Navigation />
    </>
  );
};
